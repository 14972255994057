import { template as template_24fb04a8c9904c5c8b3f6d72de7287fd } from "@ember/template-compiler";
const FKLabel = template_24fb04a8c9904c5c8b3f6d72de7287fd(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
