import { template as template_fc6733f7173d44b28f60bb4461039a22 } from "@ember/template-compiler";
const FKControlMenuContainer = template_fc6733f7173d44b28f60bb4461039a22(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
